<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from 'axios';
// import { required, email, minLength } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Lotes",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Lotes",
      items: [
        {
          text: "Lotes",
          active: true
        }
      ],
      lots: [],
      customers: {
        name: "",
        email: "",
        password: "",
      },
      submitted: false,
      showmodal: false,
      user: null,
      checked: true,
      loading: true,
      search: null,
    };
  },
  created(){
    this.user = JSON.parse(localStorage.getItem('user'));
    this.getData()
  },
  methods:{
    getData(url){
      this.loading = true
      let config = {
        method: 'get',
        url: url ? url : `${process.env.VUE_APP_API}api/v1/admin/lot`,
        headers: { 
          'Accept': 'application/json', 
          'Authorization': `Bearer ${this.user.access_token}`
        }
      };

      axios(config)
      .then( (response) => {
        this.lots = response.data.data
        this.loading = false

        this.lots.data.map( (item, index) => {
          this.lots.data[index].checked = item.status == 1 ? true : false
          if (!item.title) {
            this.lots.data[index].title = item.animals[0].name
            
          }
        })
        
      })
      .catch(function (error) {
        console.log(error);
      });
    },

    /**
     * Filter the data of search
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    changeStatus(status, id){
      let config = {
        method: 'PUT',
        url: `${process.env.VUE_APP_API}api/v1/lot/disable/${id}`,
        headers: { 
          'Accept': 'application/json', 
          'Authorization': `Bearer ${this.user.access_token}`
        },
        data:{
          enable: status
        }
      };

      axios(config)
      .then( (response) => {
        response
      })
    },
    deleteLot(id){
      Swal.fire({
        title: "Tem certeza?",
        text: "Esse registro será deletado permanentemente!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Cancelar!"
      }).then(result => {
        if (result.value) {
           let config = {
            method: 'DELETE',
            url: `${process.env.VUE_APP_API}api/v1/lot/${id}`,
            headers: { 
              'Accept': 'application/json', 
              'Authorization': `Bearer ${this.user.access_token}`
            }
          };
          axios(config)
          .then( () => {
            this.getData()
            Swal.fire("Excluído!", "O registro já foi removido.", "success");
          })
          
        }
      });
    },
    paginate(value){
      let link = this.lots.links[value].url
      this.getData(link)
    },
    onQueryChange() {
      if (this.search.length == 0) {
        this.getData()
      }
    }
  },
  watch: {
    search: function (val) {
      if (val.length >= 3) {
        this.getData(`${process.env.VUE_APP_API}api/v1/admin/lot?search=${val}`)
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-xl-3 col-lg-4">
        <div class="card">
          <div class="card-header bg-transparent border-bottom">
            <h5 class="mb-0">Filtros</h5>
          </div>

          <div class="card-body border-top">
            <div>
              <h5 class="font-size-14 mb-4">Buscar</h5>
              <b-form-group
                id="example-search"
              >
                <b-form-input
                  id="search"
                  type="search"
                  v-model="search"
                  @input="onQueryChange"
                ></b-form-input>
              </b-form-group>
              
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-9">
        <div class="card">
          
          <div class="card-body">
            
            <div class="table-responsive mt-3">
              <b-overlay :show="loading" rounded="sm">
                <table
                  class="table table-centered datatable dt-responsive nowrap"
                  style="border-collapse: collapse; border-spacing: 0; width: 100%;"
                >
                  <thead class="thead-light">
                    <tr>
                      <th style="width: 20px;">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="customercheck" />
                          <label class="custom-control-label" for="customercheck">&nbsp;</label>
                        </div>
                      </th>
                      <th>Lote</th>
                      <th>Valor</th>
                      <th>Anunciante</th>
                      <th>Tipo</th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in lots.data" :key="index">
                      <td>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            :id="`customercheck${index}`"
                          />
                          <label class="custom-control-label" :for="`customercheck${index}`">&nbsp;</label>
                        </div>
                      </td>
                      <td>{{item.title}}</td>
                      <td>R$ {{item.amount | price}}</td>
                      <td>{{item.user.name}}</td>
                      <td>
                        <div
                          class="badge font-size-12"
                          :class="{'badge-soft-danger': `${item.single_sale}` == 1,
                          'badge-soft-success': `${item.single_sale}` == 2,
                          'badge-soft-warning': `${item.single_sale}` == 3}"
                        >{{ item.single_sale == 1 ? 'Venda direta' : 'Venda por lance'}}</div>
                      </td>   
                      <td>
                        <b-form-checkbox switch v-model="item.checked" @change="changeStatus(item.checked, item.uuid)" size="lg"></b-form-checkbox>
                      </td>
                      <td>
                        <router-link
                          class="mr-3 text-primary"
                          v-b-tooltip.hover
                          title="Editar"
                          :to="`/lot/${item.uuid}`"
                        >
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </router-link>
                        <a
                          class="text-danger"
                          v-b-tooltip.hover
                          title="Excluir"
                          @click="deleteLot(item.uuid)"
                        >
                          <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-overlay>

              <b-pagination
                class="mt-4"
                v-model="lots.current_page"
                :total-rows="lots.total"
                :per-page="lots.per_page"
                aria-controls="my-table"
                align="center"
                @change="paginate"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>